import axios from "@/utils/axios/axios";

// 登录(包含登录成员)
export function loginAPI(data) {
    return axios({
        url: 'client/v3/userLogin/login',
        method: 'post',
        data
    })
}

// 登录（设置手机验证码）
export function setMobileLoginAPI(data) {
    return axios({
        url: 'client/v3/verifyCode/setMobileLogin',
        method: 'post',
        data
    })
}

// 用户信息
export function getInfoAPI(data) {
    return axios({
        url: 'client/v3/user/getInfo',
        method: 'post',
        data
    })
}
// 登出
export function logoutAPI(data) {
    return axios({
        url: 'client/v3/userLogin/Logout',
        method: 'post',
        data
    })
}
// 获取登录用户的权限树
export function getLoginUserRuleTreeListAPI(data) {
    return axios({
        url: 'client/v3/auth/memberRule/getLoginUserRuleTreeList',
        method: 'post',
        data
    })
}

// 获取前端代码版本
export function getFrontVersionAPI(data) {
    return axios({
        url: 'client/v3/version/getFrontVersion',
        method: 'post',
        data,
    })
}

// 指导视频列表
export function getGuideVideoListAPI(data) {
    return axios({
        url: 'client/v3/sop/getGuideVideoList',
        method: 'post',
        data,
    })
}


// 根据单号 打印面单pdf
export function getExpressBillPdfAPI(data) {
    return axios({
        url: 'v2/Openapi/getExpressBillPdf',
        method: 'post',
        data,
    })
}
// 系统基本信息
export function systemBasicAPI(data) {
    return axios({
        url: 'v2/Category/systemBasic',
        method: 'post',
        data,
    })
}


// 渠道类型列表/平台类型
export function channelAddressAPI(data) {
    return axios({
      url: 'v2/Category/channelAddress',
      method: 'post',
      data
    })
  }
  // 平台仓库列表
export function warehouseListAPI(data) {
    return axios({
      url: 'v2/Category/warehouseList',
      method: 'post',
      data
    })
  }