import "core-js/modules/es.array.push.js";
import { reactive, toRefs, getCurrentInstance, watch } from "vue";
const __default__ = {
  props: {
    multiple: {
      default: true
    }
  },
  setup(props, content) {
    const {
      proxy
    } = getCurrentInstance();
    const state = reactive({
      uploadPercent: "0",
      fileList: proxy.$attrs['file-list'] || [],
      // 上传
      headers: {
        authKey: localStorage.getItem("authKey")
      },
      dir: proxy.$attrs['dir'] || 'new_staff',
      baseURL: process.env.VUE_APP_BASE_URL
    });
    watch(() => proxy.$attrs['file-list'], val => {
      state.fileList = proxy.$attrs['file-list'];
    }, {
      deep: true
    });

    // 上传时
    const handleProgress = file => {
      state.uploadPercent = parseInt(file.percent) - 10 + '%';
    };
    // 上传成功时
    const handleSuccess = (res, uploadFile, uploadFiles) => {
      if (res.code == 0) {
        state.fileList.push({
          name: uploadFile.name,
          src: res.data.src,
          uid: uploadFile.uid,
          url: res.data.ossSrc
        });
      } else {
        let index = uploadFiles.findIndex(item => item.uid == uploadFile.uid);
        uploadFiles.splice(index, 1);
        proxy.$TcMsg({
          type: "error",
          text: res.error
        });
      }
      content.emit("uploadSuccess", state.fileList);
    };
    // 列表移除文件
    const handleRemove = file => {
      const uploadFiles = state.fileList;
      state.fileList = [];
      uploadFiles.map(item => {
        if (item.uid != file.uid) {
          state.fileList.push({
            ...item
          });
        }
      });
      content.emit("removeSuccess", state.fileList);
    };

    // 超出限制时
    const handleExceed = (files, uploadFiles) => {
      proxy.$TcMsg({
        type: "error",
        text: '仅支持上传' + proxy.$attrs.limit + '个文件',
        time: 1500
      });
    };
    return {
      ...toRefs(state),
      handleRemove,
      handleProgress,
      handleSuccess,
      handleExceed
    };
  }
};
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
  _useCssVars(_ctx => ({
    "0138ed82": _ctx.uploadPercent
  }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__ ? (props, ctx) => {
  __injectCSSVars__();
  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;